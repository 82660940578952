import React, { useState } from 'react';
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as Tabs from '@radix-ui/react-tabs';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import { Splide, SplideSlide } from '@splidejs/react-splide'
import '../../node_modules/@splidejs/splide/dist/css/splide.min.css'

import cashAppLogo from '../images/logos/cashapp.svg'
import cashAppQRcode from '../images/logos/home2022.png'
import AlphaBetasLogo from '../images/logos/alpha-betas-logo.svg'
import dashboardEddie from '../images/energydivision/dashboard_eddie.png'
import dashboardBuck from '../images/energydivision/dashboard_buck.png'
import dashboardMason from '../images/energydivision/dashboard_mason.png'
import dashboardTommy from '../images/energydivision/dashboard_tommy.png'
import folderButton from '../images/icons/blue-folder-icon.svg'
import folderButtonLocked from '../images/icons/blue-folder-lock-icon.svg'
import closeWhiteButton from '../images/icons/close-icon-wht.svg'
import assetEddiePlayer from '../images/energydivision/eddie/eddie-player.jpg'
import assetEddieMotivation from '../images/energydivision/eddie/eddie-motivation.jpg'

import assetBuckPlayer from '../images/energydivision/buck/abs_character_teasers_1x1_buck.jpg'
import assetBuckSexDoll from '../images/energydivision/buck/ab_buck_sexdoll2.jpg'

import assetMasonPlayer from '../images/energydivision/mason/ab_mason-id.jpg'
import assetMasonCharacter from '../images/energydivision/mason/abs_character_teasers_1x1_mason.jpg'

import assetTommySexDoll from '../images/energydivision/tommy/ab_tommy_sexdoll.png'
import assetTommyCharacter from '../images/energydivision/tommy/abs_character_teasers_1x1_tommy.jpg'

import assetArea52Document1 from '../images/energydivision/ab_area51_letter.jpg'
import assetArea52Document2 from '../images/energydivision/ab_classified.jpg'

import '@aws-amplify/ui/dist/style.css';
import { withAuthenticator } from "aws-amplify-react"

import { uniqueNamesGenerator, Config, adjectives, colors, animals } from 'unique-names-generator';

import Typewriter from 'typewriter-effect';

const DashboardPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`


  const [openEddie, setOpenEddie] = React.useState(false);
  const [openAre51, setOpenAre51] = React.useState(false);
  const [openBuck, setOpenBuck] = React.useState(false);
  const [openMason, setOpenMason] = React.useState(false);
  const [openTommy, setOpenTommy] = React.useState(false);



  const handleNameGeneratorClick = () => {
    const names = ["Brave", "Horrible", "Awful", "Rich", "Fatal", "Affable", "Anxious", "Befuddled", "Broken", "Bashful", "Critical", "Charming", "Clever", "Obnoxious", "Fast", "Slow", "Colorful", "Confused", "Sexy", "Destructive", "Disgusted", "Rancid", "Depressed", "Dysfuntional", "Difficult", "Disturbed", "Lazy", "Dull", "Defiant", "Eager", "Elegant", "Enchanting", "Evil", "Expensive", "Faithful", "Famous", "Fancy", "Fantastic", "Fierce", "Filthy", "Fine", "Foolish", "Fragile", "Friendly", "Frightened", "Funny", "Gifted", "Glamorous", "Glorious", "Gorgeous", "Grotesque", "Grumpy", "Handsome", "Happy", "Helpless", "Homeless", "Homely", "Comely", "Hungry", "Ill", "Impossible", "Innocent", "Itchy", "Jealous", "Jittery", "Jolly", "Lonely", "Lucky", "Magnificent", "Misty", "Motionless", "Muddy", "Mysterious", "Nasty", "Naughty", "Nervous", "Nutty", "Odd", "Outrageous", "Outstanding", "Panicky", "Perfect", "Plain", "Poor", "Powerful", "Precious", "Prickly", "Putrid", "Puzzled", "Quaint", "Real", "Repulsive", "Scary", "Selfish", "Shiny", "Shy", "Silly", "Sleepy", "Smiling", "Smoggy", "Sore", "Sparkling", "Splendid", "Strange", "Stupid", "Super", "Tame", "Tasty", "Tender", "Terrible", "Thoughtless", "Tired", "Tough", "Troubled", "Ugly", "Ugliest", "Unusual", "Upset", "Uptight", "Weary", "Wicked", "Wild", "Worried", "Absurd", "Groovy", "Grim", "Absent", "Restored", "Harmless", "Canceled", "Crunchy", "Spunky", "Rusty", "Ruthless"]
    
    const tagName = uniqueNamesGenerator({
      dictionaries: [names, names],
      separator: '',
    });

    const nameGeneratedDiv = document.getElementById('nameGenerated');
    // console.log(tagName, nameGeneratedDiv)
    nameGeneratedDiv.innerHTML = `$${tagName.substring(0, 11)}`;
    
  }

  const names = ["Brave", "Horrible", "Awful", "Rich", "Fatal", "Affable", "Anxious", "Befuddled", "Broken", "Bashful", "Critical", "Charming", "Clever", "Obnoxious", "Fast", "Slow", "Colorful", "Confused", "Sexy", "Destructive", "Disgusted", "Rancid", "Depressed", "Dysfuntional", "Difficult", "Disturbed", "Lazy", "Dull", "Defiant", "Eager", "Elegant", "Enchanting", "Evil", "Expensive", "Faithful", "Famous", "Fancy", "Fantastic", "Fierce", "Filthy", "Fine", "Foolish", "Fragile", "Friendly", "Frightened", "Funny", "Gifted", "Glamorous", "Glorious", "Gorgeous", "Grotesque", "Grumpy", "Handsome", "Happy", "Helpless", "Homeless", "Homely", "Comely", "Hungry", "Ill", "Impossible", "Innocent", "Itchy", "Jealous", "Jittery", "Jolly", "Lonely", "Lucky", "Magnificent", "Misty", "Motionless", "Muddy", "Mysterious", "Nasty", "Naughty", "Nervous", "Nutty", "Odd", "Outrageous", "Outstanding", "Panicky", "Perfect", "Plain", "Poor", "Powerful", "Precious", "Prickly", "Putrid", "Puzzled", "Quaint", "Real", "Repulsive", "Scary", "Selfish", "Shiny", "Shy", "Silly", "Sleepy", "Smiling", "Smoggy", "Sore", "Sparkling", "Splendid", "Strange", "Stupid", "Super", "Tame", "Tasty", "Tender", "Terrible", "Thoughtless", "Tired", "Tough", "Troubled", "Ugly", "Ugliest", "Unusual", "Upset", "Uptight", "Weary", "Wicked", "Wild", "Worried", "Absurd", "Groovy", "Grim", "Absent", "Restored", "Harmless", "Canceled", "Crunchy", "Spunky", "Rusty", "Ruthless"]
  const { uniqueNamesGenerator } = require('unique-names-generator');
  const tagName = uniqueNamesGenerator({
    dictionaries: [names, names],
    separator: '',
  });
  

  return (
    <Layout location={location} title={siteTitle} className="bg-black">
    <Seo title="Dashboard" />

    <ParallaxProvider>
      <div className="w-full grid grid-cols-1 gap-0 md:gap-5 dashboardPage">

        <Parallax speed={-10}>
          <div className="bg-hq-cia-operations-mobile md:bg-hq-cia-operations bg-fixed bg-cover w-full h-screen fixed -top-[140px] z-0" />
        </Parallax>

        <div className="w-full mx-auto max-w-[800px] md:pb-28 mt-24 z-40 p-3">

          {/* <div className="grid grid-cols-12 gap-0 w-full">
            <div className="col-span-1"></div>
            <div className="col-span-11">
              <div className="rounded-t-lg bg-ab-yellow-500 text-black w-1/2 md:w-1/3 text-center font-bold text-lg p-1">Name Generator</div>
            </div>
          </div> */}

          {/* <div className="grid grid-cols-12 gap-0 pb-5 w-full">
            <div className="col-span-1 bg-dashboard-metrics-small bg-contain bg-no-repeat" />
            <div className="col-span-11">
              <div className=" p-3 border-[7px] border-ab-yellow-500 rounded-r-lg bg-bodyBluePattern888 md:bg-bodyBluePattern1440 bg-cover">
                <h1 className="text-2xl mb-3 font-bold text-center">Mission: <img src={cashAppLogo} className="w-full md:w-64 mx-auto" /></h1>
                <p className="text-base text-center mb-3">Generate your own Alpha Betas themed <strong>$Cashtag</strong> below.</p>
                <div className="w-full py-5 rounded-lg text-xl font-bold bg-ed-blue-200 text-white my-2 shadow-lg text-center">
                 <Typewriter
                  options={{
                    autoStart: true,
                    loop: false,
                    delay: 80,
                  }}
                  onInit={(typewriter) => {
                    typewriter.typeString(tagName)
                      .start();
                  }}
                /> 
                <div id="nameGenerated">${tagName.substring(0, 11)}</div>
                </div>
                <button onClick={() => { handleNameGeneratorClick() }} className="w-full block uppercase text-black md text-base font-bold rounded rounded-3xl mt-3 mb-5 p-5 bg-ab-yellow-400">Generate</button>
                <div className="">
                  <div className="inline-block m-auto w-full">
                    <div className="border-b-2 rounded rounded-lg border-ab-yellow-500" />
                      <p className="text-base md:text-lg md:leading-[25px] text-center py-3">Download Cash App to secure your handle.<span className="text-xs">*</span></p>
                    </div>
                    <div className="hidden md:flex w-full border border-white rounded rounded-2xl grid grid-cols-2 gap-0 p-1">
                      <div className="m-auto text-lg font-bold text-center px-8">Download Cash App</div>
                      <div className=""><img src={cashAppQRcode} className="w-24 float-right rounded rounded-xl" /></div>
                    </div> 
                    <a href="https://click.cash.app/ui6m/a2022" className="block md:hidden text-center rounded rounded-3xl bg-[#00D54B] text-black mx-auto p-5">
                      <button className="m-auto text-base font-bold text-center px-8">
                          Download Cash App
                      </button>
                    </a> 
                    <div className="text-[8pt] p-3 md:leading-[13px]">*Alpha Betas generated $cashtag is not guaranteed to be available on CashApp.</div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="grid grid-cols-12 gap-0 pd-5 w-full">
            <div className="col-span-1 bg-dashboard-metrics-small bg-contain bg-no-repeat" />
            <div className="col-span-11">

                <Tabs.Root defaultValue="tab1" orientation="vertical" className="sticky lg:top-5 md:px-3 lg:px-0">
                    <Tabs.List aria-label="tabs" className="grid grid-cols-4 gap-0">
                      <div className="rounded-t-lg bg-ed-blue-200">
                        <Tabs.Trigger value="tab1" className="w-full font-bold text-xs px-3 md:px-5 py-2 border-l-2 border-r-2 rounded-t-lg border-t-2 border-ed-blue-300 mr-1 aria-selected:bg-ed-blue-100">EDDIE</Tabs.Trigger>
                      </div>
                      <div className="rounded-t-lg bg-ed-blue-200">
                        <Tabs.Trigger value="tab2" className="w-full font-bold text-xs px-3 md:px-5 py-2 border-l-2 border-r-2 rounded-t-lg border-t-2 border-ed-blue-300 mr-1 aria-selected:bg-ed-blue-100">BUCK</Tabs.Trigger>
                      </div>
                      <div className="rounded-t-lg bg-ed-blue-200">
                        <Tabs.Trigger value="tab3" className="w-full font-bold text-xs px-3 md:px-5 py-2 border-l-2 border-r-2 rounded-t-lg border-t-2 border-ed-blue-300 mr-1 aria-selected:bg-ed-blue-100">MASON</Tabs.Trigger>
                      </div>
                      <div className="rounded-t-lg bg-ed-blue-200">
                        <Tabs.Trigger value="tab4" className="w-full font-bold text-xs px-3 md:px-5 py-2 border-l-2 border-r-2 rounded-t-lg border-t-2 border-ed-blue-300 mr-1 aria-selected:bg-ed-blue-100">TOMMY</Tabs.Trigger>
                      </div>
                    </Tabs.List>

                  <Tabs.Content value="tab1">

                    <div className="border-x-2 border-ed-blue-300 grid grid-cols-3 gap-2 lg:flex p-3 lg:px-5 bg-ed-blue-100">
                      <div className="lg:mr-3">
                        <button onClick={() => setOpenEddie(true)} className="w-full flex gap-2 items-center justify-center bg-ed-blue-100 px-2 lg:px-5 py-2 rounded-lg lg:rounded-2xl hover:bg-ed-blue-200">
                          <img src={folderButton} className="w-3 lg:w-6" />
                          <span className="text-left text-xs lg:text-md">Documents</span>
                        </button>
                        <Modal 
                          open={openEddie} 
                          onClose={() => setOpenEddie(false)} 
                          center 
                          classNames={{
                              overlay: '',
                              closeButton: '!hidden',
                              modal: '!max-w-none !m-0 !p-0 !bg-black !fixed top-0 left-0 hidden !w-full h-full outline-none overflow-x-hidden overflow-y-auto bg-ed-blue-400 bg-cover z-40',
                          }}
                        >
                          <button onClick={() => setOpenEddie(false)} className="absolute top-0 right-0 p-5 z-20"><img src={closeWhiteButton} className="w-6" /></button>
                          <Splide 
                            options={ {
                              rewind: true,
                              pagination: false,
                              perPage: 1,
                              gap   : '0.5rem',
                              drag   : 'free'
                            } }
                            aria-label="latest-projects">
                              <SplideSlide className="py-2 flex h-screen">
                                <div className="m-auto">
                                  <img src={assetEddiePlayer} className="md:max-w-xl" />
                                </div>
                              </SplideSlide>

                              <SplideSlide className="py-2 flex h-screen">
                                <div className="m-auto">
                                  <img src={assetEddieMotivation} className="md:max-w-xl" />
                                </div>
                              </SplideSlide>
                            </Splide>
                        </Modal>
                      </div>

                      <div className="lg:mr-3">
                        <button onClick={() => setOpenAre51(true)} className="w-full flex gap-2 items-center justify-center bg-ed-blue-100 px-2 lg:px-3 py-2 rounded-lg lg:rounded-2xl hover:bg-ed-blue-200">
                          <img src={folderButton} className="w-3 lg:w-6" />
                          <span className="text-left text-xs lg:text-m">Area 51</span>
                        </button>
                      </div>
                      
                      <div className="lg:mr-3">
                        <button className="w-full flex gap-2 items-center justify-center opacity-50 bg-ed-blue-100 px-2 lg:px-5 py-2 rounded-lg lg:rounded-2xl hover:cursor-pointer">
                          <img src={folderButtonLocked} className="w-3 lg:w-6" />
                          <span className="text-left text-xs lg:text-md">Missions</span>
                        </button>
                      </div>

                    </div>
                    
                    <div className="border-[7px] border-ab-yellow-500 rounded-b-lg inline-block bg-profile-animated bg-cover w-full pl-10 pr-20 py-8 lg:px-10 lg:pt-12 lg:pr-28 lg:pl-14">
                      <img src={dashboardEddie} className="block" />
                    </div>

                  </Tabs.Content>
                  <Tabs.Content value="tab2">
                    
                  <div className="border-x-2 border-ed-blue-300 grid grid-cols-3 gap-2 lg:flex p-3 lg:px-5 bg-ed-blue-100">

                    <div className="lg:mr-3">
                      <button onClick={() => setOpenBuck(true)} className="w-full flex gap-2 items-center justify-center bg-ed-blue-100 px-2 lg:px-5 py-2 rounded-lg lg:rounded-2xl hover:bg-ed-blue-200">
                        <img src={folderButton} className="w-3 lg:w-6" />
                        <span className="text-left text-xs lg:text-md">Documents</span>
                      </button>
                      <Modal 
                          open={openBuck} 
                          onClose={() => setOpenBuck(false)} 
                          center 
                          classNames={{
                              overlay: '',
                              closeButton: '!hidden',
                              modal: '!max-w-none !m-0 !p-0 !bg-black !fixed top-0 left-0 hidden !w-full h-full outline-none overflow-x-hidden overflow-y-auto bg-ed-blue-400 bg-cover z-40',
                          }}
                        >
                          <button onClick={() => setOpenBuck(false)} className="absolute top-0 right-0 p-5 z-20"><img src={closeWhiteButton} className="w-6" /></button>
                          <Splide 
                            options={ {
                              rewind: true,
                              pagination: false,
                              perPage: 1,
                              gap   : '0.5rem',
                              drag   : 'free'
                            } }
                            aria-label="latest-projects">
                              <SplideSlide className="py-2 flex h-screen">
                                <div className="m-auto">
                                  <img src={assetBuckPlayer} className="md:max-w-xl" />
                                </div>
                              </SplideSlide>

                              <SplideSlide className="py-2 flex h-screen">
                                <div className="m-auto">
                                  <img src={assetBuckSexDoll} className="md:max-w-xl" />
                                </div>
                              </SplideSlide>
                            </Splide>
                        </Modal>
                    </div>

                    <div className="lg:mr-3">
                      <button onClick={() => setOpenAre51(true)} className="w-full flex gap-2 items-center justify-center bg-ed-blue-100 px-2 lg:px-3 py-2 rounded-lg lg:rounded-2xl hover:bg-ed-blue-200">
                        <img src={folderButton} className="w-3 lg:w-6" />
                        <span className="text-left text-xs lg:text-m">Area 51</span>
                      </button>
                    </div>

                    <div className="lg:mr-3">
                      <button className="w-full flex gap-2 items-center justify-center opacity-50 bg-ed-blue-100 px-2 lg:px-5 py-2 rounded-lg lg:rounded-2xl hover:cursor-pointer">
                        <img src={folderButtonLocked} className="w-3 lg:w-6" />
                        <span className="text-left text-xs lg:text-md">Missions</span>
                      </button>
                    </div>

                  </div>

                  <div className="border-[7px] border-ab-yellow-500 rounded-b-lg inline-block bg-profile-animated bg-cover w-full pl-10 pr-20 py-8 lg:px-10 lg:pt-12 lg:pr-28 lg:pl-14">
                    <img src={dashboardBuck} />
                  </div>
                    
                  </Tabs.Content>
                  <Tabs.Content value="tab3">

                  <div className="border-x-2 border-ed-blue-300 grid grid-cols-3 gap-2 lg:flex p-3 lg:px-5 bg-ed-blue-100">

                    <div className="lg:mr-3">
                      <button onClick={() => setOpenMason(true)} className="w-full flex gap-2 items-center justify-center bg-ed-blue-100 px-2 lg:px-5 py-2 rounded-lg lg:rounded-2xl hover:bg-ed-blue-200">
                        <img src={folderButton} className="w-3 lg:w-6" />
                        <span className="text-left text-xs lg:text-md">Documents</span>
                      </button>
                      <Modal 
                          open={openMason} 
                          onClose={() => setOpenMason(false)} 
                          center 
                          classNames={{
                              overlay: '',
                              closeButton: '!hidden',
                              modal: '!max-w-none !m-0 !p-0 !bg-black !fixed top-0 left-0 hidden !w-full h-full outline-none overflow-x-hidden overflow-y-auto bg-ed-blue-400 bg-cover z-40',
                          }}
                        >
                          <button onClick={() => setOpenMason(false)} className="absolute top-0 right-0 p-5 z-20"><img src={closeWhiteButton} className="w-6" /></button>
                          <Splide 
                            options={ {
                              rewind: true,
                              pagination: false,
                              perPage: 1,
                              gap   : '0.5rem',
                              drag   : 'free'
                            } }
                            aria-label="latest-projects">
                              <SplideSlide className="py-2 flex h-screen">
                                <div className="m-auto">
                                  <img src={assetMasonCharacter} className="md:max-w-xl" />
                                </div>
                              </SplideSlide>

                              <SplideSlide className="py-2 flex h-screen">
                                <div className="m-auto">
                                  <img src={assetMasonPlayer} className="md:max-w-xl" />
                                </div>
                              </SplideSlide>
                            </Splide>
                        </Modal>
                    </div>

                    <div className="lg:mr-3">
                      <button onClick={() => setOpenAre51(true)} className="w-full flex gap-2 items-center justify-center bg-ed-blue-100 px-2 lg:px-3 py-2 rounded-lg lg:rounded-2xl hover:bg-ed-blue-200">
                        <img src={folderButton} className="w-3 lg:w-6" />
                        <span className="text-left text-xs lg:text-m">Area 51</span>
                      </button>
                    </div>

                    <div className="lg:mr-3">
                        <button className="w-full flex gap-2 items-center justify-center opacity-50 bg-ed-blue-100 px-2 lg:px-5 py-2 rounded-lg lg:rounded-2xl hover:cursor-pointer">
                          <img src={folderButtonLocked} className="w-3 lg:w-6" />
                          <span className="text-left text-xs lg:text-md">Missions</span>
                        </button>
                    </div>

                  </div>

                  <div className="border-[7px] border-ab-yellow-500 rounded-b-lg inline-block bg-profile-animated bg-cover w-full pl-10 pr-20 py-8 lg:px-10 lg:pt-12 lg:pr-28 lg:pl-14">
                    <img src={dashboardMason} />
                  </div>

                  </Tabs.Content>
                  <Tabs.Content value="tab4">

                  <div className="border-x-2 border-ed-blue-300 grid grid-cols-3 gap-2 lg:flex p-3 lg:px-5 bg-ed-blue-100">

                  <div className="lg:mr-3">
                      <button onClick={() => setOpenTommy(true)} className="w-full flex gap-2 items-center justify-center bg-ed-blue-100 px-2 lg:px-5 py-2 rounded-lg lg:rounded-2xl hover:bg-ed-blue-200">
                        <img src={folderButton} className="w-3 lg:w-6" />
                        <span className="text-left text-xs lg:text-md">Documents</span>
                      </button>
                      <Modal 
                          open={openTommy} 
                          onClose={() => setOpenTommy(false)} 
                          center 
                          classNames={{
                              overlay: '',
                              closeButton: '!hidden',
                              modal: '!max-w-none !m-0 !p-0 !bg-black !fixed top-0 left-0 hidden !w-full h-full outline-none overflow-x-hidden overflow-y-auto bg-ed-blue-400 bg-cover z-40',
                          }}
                        >
                          <button onClick={() => setOpenTommy(false)} className="absolute top-0 right-0 p-5 z-20"><img src={closeWhiteButton} className="w-6" /></button>
                          <Splide 
                            options={ {
                              rewind: true,
                              pagination: false,
                              perPage: 1,
                              gap   : '0.5rem',
                              drag   : 'free'
                            } }
                            aria-label="latest-projects">
                              <SplideSlide className="py-2 flex h-screen">
                                <div className="m-auto">
                                  <img src={assetTommyCharacter} className="md:max-w-xl" />
                                </div>
                              </SplideSlide>

                              <SplideSlide className="py-2 flex h-screen">
                                <div className="m-auto">
                                  <img src={assetTommySexDoll} className="md:max-w-xl" />
                                </div>
                              </SplideSlide>
                            </Splide>
                        </Modal>
                    </div>

                    <div className="lg:mr-3">
                      <button onClick={() => setOpenAre51(true)} className="w-full flex gap-2 items-center justify-center bg-ed-blue-100 px-2 lg:px-3 py-2 rounded-lg lg:rounded-2xl hover:bg-ed-blue-200">
                        <img src={folderButton} className="w-3 lg:w-6" />
                        <span className="text-left text-xs lg:text-m">Area 51</span>
                      </button>
                    </div>

                    <div className="lg:mr-3">
                        <button className="w-full flex gap-2 items-center justify-center opacity-50 bg-ed-blue-100 px-2 lg:px-5 py-2 rounded-lg lg:rounded-2xl hover:cursor-pointer">
                          <img src={folderButtonLocked} className="w-3 lg:w-6" />
                          <span className="text-left text-xs lg:text-md">Missions</span>
                        </button>
                    </div>

                    </div>

                    <div className="border-[7px] border-ab-yellow-500 rounded-b-lg inline-block bg-profile-animated bg-cover w-full pl-10 pr-20 py-8 lg:px-10 lg:pt-12 lg:pr-28 lg:pl-14">
                      <img src={dashboardTommy} />
                    </div>

                  </Tabs.Content>
                </Tabs.Root>

                <Modal
                  open={openAre51} 
                  onClose={() => setOpenAre51(false)} 
                  center 
                  classNames={{
                      overlay: '',
                      closeButton: '!hidden',
                      modal: '!max-w-none !m-0 !p-0 !bg-black !fixed top-0 left-0 hidden !w-full h-full outline-none overflow-x-hidden overflow-y-auto bg-ed-blue-400 bg-cover z-40',
                  }}
                >
                  <button onClick={() => setOpenAre51(false)} className="absolute top-0 right-0 p-5 z-20"><img src={closeWhiteButton} className="w-6" /></button>
                    <Splide 
                      options={ {
                        rewind: true,
                        pagination: false,
                        width : '100%',
                        perPage: 1,
                        gap   : '0.5rem',
                        drag   : 'free',
                      } }
                      aria-label="latest-projects">
                        <SplideSlide className="py-2 flex h-screen">
                          <div className="m-auto">
                            <img src={assetArea52Document1} className="md:max-w-xl" />
                          </div>
                        </SplideSlide>
                        <SplideSlide className="py-2 flex h-screen">
                          <div className="m-auto">
                            <img src={assetArea52Document2} className="md:max-w-xl" />
                          </div>
                        </SplideSlide>
                    </Splide>
                </Modal>

            </div>
          </div>
          
        </div>
      </div>
      <div className="block fixed bottom-0 w-full h-[80px] md:h-[300px] bg-grid-dashboard-controller-mobile md:bg-grid-dashboard-controller bg-cover z-50" />

    </ParallaxProvider>
    </Layout>
  )
}

export default DashboardPage
// export default withAuthenticator(DashboardPage, {
//   usernameAttributes: 'email',
//   signUpConfig: {
//     // header: 'My Customized Sign Up',
//     // hideAllDefaults: true,
//     // defaultCountryCode: '1',
//     signUpFields: [
//         {
//             label: 'Email',
//             key: 'username',
//             required: true,
//             type: 'email',
//             placeholder: 'Email',
//             displayOrder: 1,
//         },
//         {
//             label: 'Password',
//             key: 'password',
//             required: true,
//             type: 'password',
//             placeholder: 'Password',
//             displayOrder: 2,
//         },
//         {
//             label: 'Phone Number (optional)',
//             key: 'phone_number',
//             required: false,
//             type: 'phone_number',
//             displayOrder: 3,
//         }
//     ],
//     hiddenDefaults: ['username', 'email'],
//   }
// })

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
